<script>
import { wpService } from "@/services/wp";
export default {
  data() {
    return {
      gift: null,
    };
  },

  methods: {
    confirmReject() {
      this.$buefy.dialog.confirm({
        title: "Confirmar",
        message:
          "Are you sure you want to <b>reject</b> this gift? This action cannot be undone.",
        confirmText: "Rechazar regalo",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.reject(),
      });
    },
    async reject() {
      const loading = this.$buefy.loading.open();
      await wpService.gifts
        .reject(this.gift.gift_id)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          this.manageError(err.code);
        });
      loading.close();
    },
    async accept() {
      const loading = this.$buefy.loading.open();
      await wpService.gifts
        .accept(this.gift.gift_id)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          this.manageError(err.code);
        });
      loading.close();
    },
    manageError(errorCode = "") {
      let message = "Enlace inválido.";
      switch (errorCode) {
        case "not_recipient":
          message = "Oops! Este regalo no te pertenece.";
          break;
        case "REJECTED":
          message = "Oops! Este regalo fue rechazado.";
          break;
        case "CANCELLED":
          message = "Oops! Este regalo fue cancelado.";
          break;
        case "DELIVERED":
          message = "Oops! Este regalo ya fue entregado.";
          break;
        case "EXPIRED":
          message = "Oops! Este regalo ha expirado.";
          break;
      }
      this.$router.push({ name: "home" });
      this.$buefy.dialog.alert(message);
    },
  },

  created() {
    const gift_id = Number(this.$route.query.id) || 0;
    if (gift_id < 1) {
      return this.$router.replace({ name: "home" });
    }
    wpService.gifts
      .get(gift_id)
      .then(gift => {
        if (gift.gift_status !== "PENDING") {
          this.manageError(gift.gift_status);
          return;
        }
        this.gift = gift;
      })
      .catch(err => {
        console.log("error", err);
        this.manageError(err.code);
      });
  },
};
</script>

<template>
  <div class="layout">
    <div v-if="gift" class="gift">
      <img src="@/assets/gift.png" />
      <h1 class="gift__amount">
        {{ gift.gift_amount | formatMoney(0) }}
        {{ gift.gift_amount > 1 ? "Créditos" : "Crédito" }}
      </h1>
      <p class="gift__sender">
        Enviado por <b>{{ gift.gift_sender_name }}</b>
      </p>
      <button
        class="button is-success is-rounded is-fullwidth accept"
        @click="accept"
      >
        Aceptar
      </button>
      <button class="button is-text" @click="confirmReject">
        No, gracias
      </button>
      <div></div>
      <!-- <hr /> -->
      <p class="current_balance">
        <span class="da__label">Tu balance actual:</span>
        <span class="da__amount">{{
          $store.getters["session/userCredits"] | formatMoney(0)
        }}</span>
      </p>
    </div>
    <b-loading v-else :active="true" />
  </div>
</template>

<style lang="scss" scoped>
.layout {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.gift {
  img {
    width: 150%;
    margin-left: -25%;
    margin-bottom: 1rem;
    max-width: none;
  }
  .gift__amount {
    font-size: 2rem;
    font-weight: 500;
  }
  .current_balance {
    background-color: rgb(246, 246, 246);
    display: inline-block;
    padding: 0.375rem 1rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    margin-top: 1.5rem;
    .da__label {
      font-weight: bold;
      opacity: 0.5;
      margin-right: 0.25rem;
    }
    .da__amount {
      font-weight: bold;
    }
  }
}
.accept {
  margin-top: 1.5rem;
  font-weight: bold;
}
.buttons {
  margin-top: 1rem;
  justify-content: center;
}
@media (max-width: 425px) {
  .layout {
    background-color: #fff;
  }
}
@media (min-width: 426px) {
  .gift {
    max-width: 20rem;
    padding: 2rem;
    border-radius: 1.5rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    img {
      margin-top: -20%;
      margin-left: -35%;
      margin-bottom: 1rem;
      width: 170%;
      max-width: none;
      position: relative;
    }
  }
}
</style>
